import client from "./requestclient";

export async function registerLandlordAndProperty(body) {
  return await client({
    URL_PATH: "realtoraddlandlordandproperty",
    method: "POST",
    body: body
  });
}

export async function getRealtorPromo() {
  return await client({
    URL_PATH: "getrealtorpromodashboard",
    method: "GET"
  });
}
export async function getWeeklyPromoData() {
  return await client({
    URL_PATH: "fetchweeklypromowinners",
    method: "GET"
  });
}

export async function getMonthlyPromoData() {
  return await client({
    URL_PATH: "fetchmonthlypromowinners",
    method: "GET"
  });
}

export async function getOverallPromoData() {
  return await client({
    URL_PATH: "fetchoverallpromowinners",
    method: "GET"
  });
}

export async function getDashboardSummaryData() {
  return await client({
    URL_PATH: "realtorextracounters",
    methods: "GET"
  });
}
