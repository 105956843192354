<template>
  <div>
    <div class="register-property-layout">
      <!-- FROMS -->
      <div class="forms-container">
        <!-- FORM 1 -->
        <div class="container" v-if="formStep == 1">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="container__head">
            <h3>Please provide the Landlord Information</h3>
            <p>
              Please fill out the following details for accurate registration
            </p>
          </div>

          <div class="form">
            <BaseInput
              type="email"
              placeholder="example@gmail.com"
              label="Email Address"
              :value="email"
              @input="email = $event"
              :errorMessage="errorMessage.email"
              @blur="validateEmail"
            />

            <div class="input-group">
              <BaseInput
                placeholder="Michael"
                label="First Name"
                :value="firstName"
                @input="firstName = $event"
                :errorMessage="errorMessage.firstName"
                @blur="validateFirstName"
              />
              <BaseInput
                label="Last Name"
                placeholder="Ndubuisi"
                :value="lastName"
                @input="lastName = $event"
                :errorMessage="errorMessage.lastName"
                @blur="validateLastName"
              />
            </div>
            <div class="input-group">
              <BaseInput
                label="Phone number"
                placeholder="+2348 1437 434"
                :value="phone"
                type="number"
                @input="phone = $event"
                :errorMessage="errorMessage.phone"
                @blur="validatePhone"
              />

              <InputSelect
                label="Gender"
                @select-option="gender = $event"
                @blur="validateGender"
                :value="gender"
                :errorMessage="errorMessage.gender"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </InputSelect>
            </div>

            <BaseInput
              label="Current address"
              placeholder="Enter full address"
              :value="address"
              @input="address = $event"
              :errorMessage="errorMessage.address"
              @blur="validateAddress"
            />
          </div>
          <div class="btn-container  flex-end">
            <BaseButtton @click="validateForm1">Next</BaseButtton>
          </div>
        </div>
        <!-- FORM 2 -->
        <div class="container" v-if="formStep == 2">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="container__head">
            <h3>Tell us about this property</h3>
            <p>
              Please provide us with the details about the property in question.
            </p>
          </div>

          <div class="form">
            <div class="email-container input-full">
              <BaseInput
                type="text"
                placeholder="Eg: newly built 3 bedroom flat"
                label="Title"
                :value="propertyTitle"
                @input="propertyTitle = $event"
                :errorMessage="errorMessage.title"
                @blur="validateTitleInput"
              />
            </div>

            <InputSelect
              label="Property Type"
              @select-option="propertyType = $event"
              @blur="validatePropertyType"
              :value="propertyType"
              :errorMessage="errorMessage.propertyType"
              :loading="loadingPropertyType"
            >
              <option
                v-for="propertyType in propertyTypes"
                :key="propertyType.id"
                :value="propertyType.type"
                >{{ propertyType.type }}</option
              >
            </InputSelect>
            <div class="input-group">
              <InputSelect
                label="Property subtype"
                @select-option="propertySubType = $event"
                @blur="validatePropertySubType"
                :value="propertySubType"
                :errorMessage="errorMessage.propertySubType"
                :loading="loadingPropertySubType"
              >
                <option
                  v-for="subType in propertySubTypes"
                  :key="subType.id"
                  :value="subType.subtype"
                  >{{ subType.subtype }}</option
                >
              </InputSelect>
              <InputSelect
                label="Business Type"
                @select-option="typeofbusiness = $event"
                @blur="validateBusinessType"
                :value="typeofbusiness"
                :errorMessage="errorMessage.typeofbusiness"
              >
                <option selected disabled>Select</option>
                <option  v-for="item in businesstypenumber"
                  :key="item.id"
                  :value="item.id">{{ item.businesstype }}</option>
              </InputSelect>
            </div>

            <BaseInput
              placeholder="Enter amount"
              label="Amount"
              :value="theRentValueAmount"
              @input="theRentValueAmount = $event"
              :errorMessage="errorMessage.amount2"
              @blur="validatePropertyValue"
            />

            <div class="input-group">
              <InputSelect
                label="Location"
                @select-option="location = $event"
                @blur="validateLocation"
                :value="location"
                :errorMessage="errorMessage.location"
                :loading="loadingLocation"
              >
                <option
                  v-for="state in sortedStates"
                  :key="state.id"
                  :value="state.name"
                  >{{ state.name }}</option
                >
              </InputSelect>
              <InputSelect
                label="Status"
                @select-option="propertyStatus = $event"
                @blur="validateStatus"
                :value="propertyStatus"
                :errorMessage="errorMessage.status"
                :loading="loadingStatus"
              >
                <option
                  v-for="status in propertyStatuses"
                  :key="status"
                  :value="status"
                  >{{ status }}</option
                >
              </InputSelect>
            </div>
            <div class="btn-container">
              <BaseButtton type="text" @click="formStep--"
                >Previous</BaseButtton
              >
              <BaseButtton @click="validateForm2">Next</BaseButtton>
            </div>
          </div>
        </div>

        <!-- FORM 3 -->
        <div class="container" v-if="formStep == 3">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="container__head">
            <h3>Let’s know more about this property</h3>
            <p>
              Please fill out the following details for accurate registration
            </p>
          </div>

          <div class="form">
            <div class="email-container">
              <div class="input-group">
                <BaseInput
                  type="number"
                  placeholder="Enter number of bedroom(s)"
                  label="How many bedrooms"
                  :value="noOfBedroom"
                  @input="noOfBedroom = $event"
                  :errorMessage="errorMessage.noOfBedroom"
                  @blur="validateBedrooms"
                />

                <InputSelect
                  label="Is the property furnished?"
                  @select-option="isFurnished = $event"
                  :value="isFurnished"
                >
                  <option value="yes">Yes</option>
                  <option value="no" selected>No</option>
                </InputSelect>
              </div>
            </div>
            <InputSelect
              label="How did you find this property?"
              @select-option="propertyAware = $event"
              :value="propertyAware"
              :loading="loadingAwareness"
            >
              <option
                v-for="awareType in propertyAwarenessTypes"
                :key="awareType"
                :value="awareType"
                >{{ awareType }}</option
              >
            </InputSelect>

            <BaseInput
              type="textarea"
              placeholder="enter a detailed details of the property"
              label="Property description"
              :value="description"
              @input="description = $event"
            />
          </div>
          <div class="btn-container">
            <BaseButtton type="text" @click="formStep--">Previous</BaseButtton>
            <BaseButtton @click="submitForm" :disabled="processing">
              {{ processing ? "Processing..." : "Submit" }}
            </BaseButtton>
          </div>
        </div>
      </div>
      <aside class="steps-container">
        <h4>Property registeration</h4>

        <div class="steps">
          <div
            class="step"
            :class="{
              progress: formStep == option.id,
              completed: formStep > option.id,
            }"
            v-for="option in formStepDetails"
            :key="option.id"
          >
            <div class="step-number-container">
              <div class="step-number">
                {{ option.id }}
                <div class="done-icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="check">
                      <path
                        id="Vector"
                        d="M13.3337 4L6.00033 11.3333L2.66699 8"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <p class="step-count">Step {{ option.id }}</p>
              <p class="current-step-name">{{ option.text }}</p>
              <div class="status-pill">
                {{
                  formStep == option.id
                    ? "In progress"
                    : formStep > option.id
                    ? "completed"
                    : "pending"
                }}
              </div>
            </div>
          </div>
          <!-- <div class="progress step">
            <div class="step-number-container">
              <div class="step-number">1</div>
            </div>
            <div>
              <p class="step-count">Step 1</p>
              <p class="current-step-name">Landlord Information</p>
              <div class="status-pill progress">completed</div>
            </div>
          </div>
          <div class="step"> 
            <div class="step-number-container">
              <div class="step-number">1</div>
            </div>
            <div>
              <p class="step-count">Step 1</p>
              <p class="current-step-name">Landlord Information</p>
              <div class="status-pill progress">completed</div>
            </div>
          </div>
          -->
        </div>
      </aside>
    </div>

    <!-- STEPS -->
  </div>
</template>

<script>
import properties from "../services/properties";
import InputSelect from "../components/form/InputSelect.vue";
import BaseButtton from "../components/UI/BaseButtton.vue";

import BaseInput from "../components/form/BaseInput.vue";
import { registerLandlordAndProperty } from "../services/promo";
export default {
  components: {
    BaseInput,
    InputSelect,
    BaseButtton,
  },
  data() {
    return {
      formStepDetails: [
        {
          id: 1,
          text: "Landlord Information",
        },
        {
          id: 2,
          text: "Property details",
        },
        {
          id: 3,
          text: "Finish",
        },
      ],
      processing: false,
      loadingPropertyType: false,
      loadingPropertySubType: false,
      loadingAwareness: false,
      loadingLocation: false,
      loadingStatus: false,
      formStep: 1,
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      gender: "",
      address: "",
      amount: "",
      amount2: "",
      age: "",
      propertyTitle: "",
      propertyTypes: [],
      propertySubTypes: [],
      propertySubType: "",
      propertyType: "",
      propertyValue: "",
      noOfBedroom: "",
      description: "",
      // leaseOrRent: "",
      isFurnished: "no",
      propertyStatuses: [],
      propertyStatus: "",
      businesstypenumber: "",
      propertyAware: "",
      states: [],
      isEmailValid: false,
      location: "",
      propertyAwarenessTypes: [],
      bedroomOptions: [],
      denomination: "naira",
      // businesstype: 1,
      typeofbusiness: "",
      subscription: "",
      subtypeId: "",
      errorMessage: {
        title: "",
        noOfBedroom: "",
        propertyType: "",
        propertySubType: "",
        // leaseOrRent: "",
        typeofbusiness: "",
        status: "",
        propertyValue: "",
        form: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        // amount: "",
        amount2: "",
        address: "",
        age: "",
        gender: "",
        location: "",
      },
    };
  },

  methods: {
    submitForm() {
      this.processing = true;
      this.errorMessage.form = "";
      if (this.subscription != "YES") {
        return this.$toast.error("Subscribe before adding property");
      }
      if (this.validateForm3()) {
        const body = {
          title: this.propertyTitle,
          lastname: this.lastName,
          firstname: this.firstName,
          gender: this.gender,
          type: this.propertyTypeId,
          subtype: this.propertySubTypeId,
          bedrooms: this.noOfBedroom,
          stateid: this.stateId,
          price: this.amount2,
          denomination: "naira",
          businesstype: Number(this.typeofbusiness),
          email: this.email,
          phone: this.phone,
          status: this.propertyStatus,
          description: this.description,
        };
        registerLandlordAndProperty(JSON.stringify(body))
          .then((res) => {
            this.processing = false;
            if (res.success) {
              (this.errorMessage.form = ""), (this.formSubmitted = true);
              this.clearForm();

              this.$toast.success("FORM SUBMITTED SUCCESSFULLY");
              // reset form step
              this.formStep = 1;
            } else {
              this.$toast.error("ERROR");
            }
          })
          .catch((err) => {
            console.log(err);
            this.processing = false;
          });
      } else {
        this.errorMessage.form = "Unable to submit. Please try again";
      }
    },

    clearForm() {
      this.typeofbusiness = "";
      this.propertyTitle = "";
      this.propertyType = "";
      this.propertySubType = "";
      this.noOfBedroom = "";
      this.state = "";
      this.propertyValue = "";
      this.email = "";
      this.landlordPhone = "";
      this.propertyStatus = "";
      this.description = "";
      // this.leaseOrRent = "";
      this.typeofbusiness = ""
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = "";
      this.amount2 = "";
      // this.amount = "";
      this.address = "";
      this.age = "";
      this.gender = "";
      this.location = "";
    },

    getPropertyTypes() {
      this.loadingPropertyType = true;
      //fetch properties
      properties
        .fetchPropertyType()
        .then((data) => {
          //fetch subproperty on first load

          // this.getPropertySubTypes();
          this.propertyTypes = data.propertytypes;
          this.loadingPropertyType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBusinessTypes() {
      //fetch properties
      properties
        .fetchBusinessTypes()
        .then((data) => {
          // console.log("Business Data", data)
          if (data.success === "success") {
            this.businesstypenumber = data.businesstype;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertySubTypes(id) {
      this.loadingPropertySubType = true;
      properties
        .fetchPropertySubType(id)
        .then((data) => {
          this.propertySubTypes = data.propertysubtypes;
          this.loadingPropertySubType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStates() {
      this.loadingLocation = true;
      properties
        .fetchStates()
        .then((data) => {
          this.loadingLocation = false;

          this.states = data.states;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPropertyAwarenessTypes() {
      this.loadingAwareness = true;

      properties.fetchPropertyAwarenessTypes().then((res) => {
        this.propertyAwarenessTypes = res.data;
        this.loadingAwareness = false;
      });
    },
    getPropertyStatuses() {
      this.loadingStatus = true;
      properties.fetchPropertyStatuses().then((data) => {
        this.propertyStatuses = data.propertystatus;
        this.loadingStatus = false;
      });
    },

    validateTitleInput() {
      if (this.propertyTitle.trim() === "") {
        this.errorMessage.title = " Please input title";
        return false;
      }
      this.errorMessage.title = "";
      return true;
    },

    validateBusinessType() {
      if (this.typeofbusiness.trim() === "") {
        this.errorMessage.typeofbusiness = " Please select business type";
        return false;
      }
      this.errorMessage.typeofbusiness = "";
      return true;
    },

    validateBedrooms() {
      // if (this.noOfBedroom.trim() === "" ) {
      //   this.errorMessage.noOfBedroom = "Please input Number of Bedrooms";
      //   return false;
      // }

      if (isNaN(this.noOfBedroom)) {
        this.errorMessage.noOfBedroom = "Number of bedrooms must be a number";
        return false;
      }

      this.errorMessage.noOfBedroom = "";
      return true;
    },

    validatePropertyType() {
      if (this.propertyType === "") {
        this.errorMessage.propertyType = "Please select a property type";
        return false;
      }

      this.errorMessage.propertyType = "";
      return true;
    },
    validatePropertySubType() {
      if (this.propertySubType === "") {
        this.errorMessage.typeofbusiness = "Please select value";
        return false;
      }

      this.errorMessage.typeofbusiness = "";
      return true;
    },
    // validateRented() {
    //   // this.errorMessage.leaseOrRent = "Please select value";

    //   if (this.leaseOrRent == "") {
    //     this.errorMessage.leaseOrRent = "Please select value";
    //     return false;
    //   }

    //   this.errorMessage.leaseOrRent = "";
    //   return true;
    // },

    validateStatus() {
      if (this.propertyStatus === "") {
        this.errorMessage.status = "Please select property status";
        return false;
      }

      this.errorMessage.status = "";
      return true;
    },
    validateLocation() {
      if (this.location === "") {
        this.errorMessage.location = "Please select state";
        return false;
      }

      this.errorMessage.location = "";
      return true;
    },

    validatePropertyValue() {
      if (this.amount2 === "") {
        this.errorMessage.amount2 = "Please enter property value";
        return false;
      }

      if (isNaN(this.amount2)) {
        this.errorMessage.amount2 = "Property value must be a Number";
        return false;
      }

      this.errorMessage.amount2 = "";
      return true;
    },
    validateFirstName() {
      if (this.firstName.trim() === "") {
        this.errorMessage.firstName = " First name required!";
        return false;
      }
      this.errorMessage.firstName = "";
      return true;
    },
    validateLastName() {
      if (this.lastName.trim() === "") {
        this.errorMessage.lastName = " Last name required!";
        return false;
      }
      this.errorMessage.lastName = "";
      return true;
    },
    validateEmail() {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      if (this.email.trim() === "") {
        this.errorMessage.email = " Email required!";
        return false;
      }

      if (!regex.test(this.email)) {
        this.errorMessage.email = "Invalid Email";
        return false;
      }

      this.errorMessage.email = "";
      return true;
    },
    validateAddress() {
      if (this.address.trim() === "") {
        this.errorMessage.address = " address required!";
        return false;
      }
      this.errorMessage.address = "";
      return true;
    },
    validatePhone() {
      if (this.phone.trim() === "") {
        this.errorMessage.phone = " Number required!";
        return false;
      }
      if (
        isNaN(this.phone.trim()) ||
        this.phone.trim().length < 11 ||
        Number(this.phone.trim()) < 0
      ) {
        this.errorMessage.phone = " Invalid number";
        return false;
      }

      this.errorMessage.phone = "";
      return true;
    },
    validateGender() {
      if (this.gender === "") {
        this.errorMessage.gender = " gender required!";
        return false;
      }
      this.errorMessage.gender = "";
      return true;
    },

    validateForm1() {
      if (this.isForm1Valid) {
        this.formStep = 2;
        return;
      }
    },
    validateForm2() {
      if (this.isForm2Valid) {
        this.formStep = 3;
        return;
      }
    },
    validateForm3() {
      return this.isForm3Valid;
    },
  },
  created() {
    this.subscription = this.$store.getters.getSubscription;
  },
  mounted() {
    this.getPropertyTypes();
    this.getStates();
    this.getPropertyAwarenessTypes();
    this.getPropertyStatuses();
    this.getBusinessTypes();
  },
  watch: {
    propertyType(newType) {
       if (newType) {
        this.subtypeId = this.propertyTypes.find((el) => el.type === newType)[
          "id"
        ];
       }

        if (this.subtypeId) {
          this.getPropertySubTypes(this.subtypeId);
        } else {
          this.propertySubTypes = [];
        }
      // if (newType) {
      //    this.subtypeId = this.propertyTypes.find((el) => el.type === newType)[
      //     "id"
      //   ];

      //   if (this.subtypeId) {
      //     this.getPropertySubTypes(this.subtypeId);
      //   } else {
      //     this.propertySubTypes = [];
      //   }
      // }
    },
  },
  computed: {
    sortedStates() {
      return [...this.states].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    propertyTypeId() {
      return (
        this.propertyTypes.find((el) => el.type === this.propertyType).id || ""
      );
    },
    propertySubTypeId() {
      return (
        this.propertySubTypes.find((el) => el.subtype === this.propertySubType)
          .id || ""
      );
    },
    stateId() {
      return this.states.find((el) => el.name === this.location).id || "";
    },

    isForm1Valid() {
      return (
        this.validateEmail() &&
        this.validateFirstName() &&
        this.validateLastName() &&
        this.validatePhone() &&
        this.validateGender() &&
        this.validateAddress()
      );
    },
    isForm2Valid() {
      return (
        this.validateTitleInput() &&
        this.validatePropertyType() &&
        this.validatePropertySubType() &&
        this.validateBusinessType() &&
        // this.validateRented() &&
        this.validatePropertyValue() &&
        this.validateLocation() &&
        this.validateStatus()
      );
    },
    isForm3Valid() {
      return this.validateBedrooms();
    },
    isFormValid() {
      return (
        this.validateTitleInput() &&
        this.validateBedrooms() &&
        this.validatePropertyType() &&
        this.validatePropertySubType() &&
        this.validateBusinessType() &&
        // this.validateRented() &&
        this.validateStatus() &&
        this.validateState() &&
        this.validatePropertyValue() &&
        this.landlordFirstname !== "" &&
        this.landlordLastname !== "" &&
        this.landlordGender !== "" &&
        !isNaN(this.landlordPhone)
      );
    },
    theRentValueAmount: {
      get() {
        return this.amount;
      },
      set(value) {
        let amountt = value.split(",").join("");
        this.amount2 = (amountt);
        let decimalArray = amountt.split(".");
        if (decimalArray.length === 2) {
          amountt = decimalArray[0];
          amountt = (+amountt).toLocaleString();
          this.amount = amountt + "." + decimalArray[1];
        } else {
          this.amount = (+amountt).toLocaleString();
        }
      },
    },
  },
};
</script>

<style scoped>
.progress {
  height: fit-content !important;
  background: none !important;
}
.input-error {
  color: rgb(234, 0, 0);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 10px;
  padding: 1rem 2rem 0 2rem;

  /* position: absolute; */
  /* bottom: -20px; */
}

.submit-btn {
  padding: 1rem 2rem;
  background-color: var(--green-color);
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 50px;
  color: white;
  margin: auto;
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}
.container__head {
  padding-top: 1.5rem;
  margin-bottom: 1rem;
}
.form-radios {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.container__head {
  padding-top: 1.5rem;
  margin-bottom: 1rem;
}

.container__head h3 {
  font-size: clamp(18px, 4vw, 24px);
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.container__head p {
  font-size: 12px;
  font-weight: 700;
  color: #555c74;
  font-weight: 300;
}
.container {
  background-color: white;
  border-radius: 10px;

  padding: 2rem;
}

.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1.5rem;
  font-family: "Lato", sans-serif;
}

.radios-label {
  line-height: 1.5;
  font-size: clamp(12px, 4vw, 1rem);
  font-weight: 700;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.input-group > * {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}

p {
  margin: 0;
}

.steps-container {
  font-family: "Lato";
  background-color: #fff;
  padding: 1.5rem 1rem;
  border-radius: 10px;
  max-width: 292px;
}
.steps-container h4 {
  margin-bottom: 1.5rem;
}

.steps > * + * {
  margin-top: 8px;
}

.step {
  display: flex;
  gap: 1rem;
  position: relative;
}
.step-number-container {
  position: relative;
}
.step:not(:last-child) .step-number-container::after {
  content: "";
  position: absolute;
  height: calc(100% - 28px);
  width: 2px;
  border-radius: 5px;
  background-color: #b1b2b2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 1;
  transition: background-color 500ms ease-in-out;
}

.step .step-count {
  color: #888fa7;

  font-size: 10px;
  line-height: 1.8;
}

.step .current-step-name {
  font-size: 12px;
  line-height: 1.8;
  color: #b1b2b2;
}

.step .status-pill {
  display: flex;
  font-size: 8px;
  letter-spacing: 0.16px;
  width: 62px;
  padding: 2px 8px;
  line-height: 1.5;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 5px 0;
  border: 1px solid #b1b2b2;
}

.progress .status-pill {
  background: #d8effd;
  color: #5aa8d7;
  border-color: transparent;
}
.completed .status-pill {
  background: #e7faf1;
  color: #48b881;
  border-color: transparent;
}

.step .step-number {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: grid;
  place-items: center;
  font-weight: 500;
  border: 2px solid #b1b2b2;
  color: #b1b2b2;

  font-size: 12px;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.step-number {
  position: relative;
  overflow: hidden;
  transition: background-color 500ms ease-in-out;
}

.step-number .done-icon {
  position: absolute;
  background-color: #0033ea;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 350ms ease-in;
  opacity: 0;
}

.pending.step .step-name {
  color: #b1b2b2;
}

.progress.step {
  color: #b1b2b2;
}

.progress.step .current-step-name {
  color: #262626;
}
.progress.step:not(:last-child) .step-number-container::after,
.completed.step:not(:last-child) .step-number-container::after {
  background-color: #0033ea;
}

.progress.step .step-number,
.completed.step .step-number {
  border-color: #0033ea;
  background-color: #0033ea;

  color: #fff;
}

.completed.step .done-icon {
  opacity: 1;
}
.completed.step .current-step-name {
  color: #0033ea;
}

.register-property-layout {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  margin-top: 2rem;
}

.steps-container {
  align-self: flex-start;
  flex-basis: 250px;
  display: none;
}

@media screen and (min-width: 1024px) {
  .steps-container {
    display: block;
  }
}

.forms-container {
  flex: 1;
}
</style>
